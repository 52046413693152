/**
 * @license Licensed under the Apache License, Version 2.0 (the "License"):
 *          http://www.apache.org/licenses/LICENSE-2.0
 */

/**
 * @fileoverview Blocks for Arduino Digital and Analogue input and output
 *     functions. The Arduino function syntax can be found at
 *     http://arduino.cc/en/Reference/HomePage
 *
 * TODO: maybe change this to a "PIN" BlocklyType
 */
/*

import * as goog from '../closure/goog/goog.js';
goog.declareModuleId('Blockly.libraryBlocks.io');

import { Hardware } from "../core/hardware.js";
import { NameType } from "../core/names.js";
import {Msg} from "../core/msg.js";
import {FieldDropdown} from '../core/field_dropdown.js';
import {Block} from '../core/block.js';
*/

'use strict';

goog.module('Blockly.libraryBlocks.io');

goog.require('Blockly.Names');
goog.require('Blockly.Hardware');
goog.require('Blockly.Msg');
goog.require('Blockly.FieldDropdown');
const {defineBlocks} = goog.require('Blockly.common');

const blocks = {};
exports.blocks = blocks;
//export {blocks};

blocks['io_digitalwrite'] = {
  /**
   * Block for creating a 'set pin' to a state.
   * @this {Blockly.Block}
   */
  init: function() {
    this.setHelpUrl('http://arduino.cc/en/Reference/DigitalWrite');
    this.appendValueInput('STATE')
        .appendField(Blockly.Msg.ARD_DIGITALWRITE)
        .appendField(new Blockly.FieldDropdown(
            Blockly.Hardware.availableDigitalPorts), 'PIN')
        .appendField(Blockly.Msg.ARD_WRITE_TO)
        .setCheck(Blockly.Names.NameType.BOOLEAN);
    this.setInputsInline(false);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setStyle('io_blocks');
    this.setTooltip(Blockly.Msg.ARD_DIGITALWRITE_TIP);
  },
  /**
   * Updates the content of the the pin related fields.
   * @this {Blockly.Block}
   
  updateFields: function() {
    Blockly.Arduino.Boards.refreshBlockBlockly.FieldDropdown(
        this, 'PIN', 'digitalPins');
  }*/
};

blocks['io_digitalread'] = {
  /**
   * Block for creating a 'read pin'.
   * @this {Blockly.Block}
   */
  init: function() {
    this.setHelpUrl('http://arduino.cc/en/Reference/DigitalRead');
    this.appendDummyInput()
        .appendField(Blockly.Msg.ARD_DIGITALREAD)
        .appendField(new Blockly.FieldDropdown(
            Blockly.Hardware.availableDigitalPorts), 'PIN');
    this.setOutput(true, Blockly.Names.NameType.BOOLEAN);
    this.setStyle('io_blocks');
    this.setTooltip(Blockly.Msg.ARD_DIGITALREAD_TIP);
  },
  /** @return {!string} The type of return value for the block, an integer. */
  getBlockType: function() {
    return 'boolean';
  },
  /**
   * Updates the content of the the pin related fields.
   * @this {Blockly.Block}
   
  updateFields: function() {
    Blockly.Arduino.Boards.refreshBlockBlockly.FieldDropdown(
        this, 'PIN', 'digitalPins');
  }*/
};

blocks['io_builtin_led'] = {
  /**
   * Block for setting built-in LED to a state.
   * @this {Blockly.Block}
   */
  init: function() {
    this.setHelpUrl('http://arduino.cc/en/Reference/DigitalWrite');
    this.appendValueInput('STATE')
        .appendField(Blockly.Msg.ARD_BUILTIN_LED)
        .appendField(new Blockly.FieldDropdown(
            Blockly.Hardware.builtInLED), 'BUILT_IN_LED')
        .appendField(Blockly.Msg.ARD_WRITE_TO)
        .setCheck(Blockly.Names.NameType.BOOLEAN);
    this.setInputsInline(false);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setStyle('io_blocks');
    this.setTooltip(Blockly.Msg.ARD_BUILTIN_LED_TIP);
  },
  /**
   * Updates the content of the the pin related fields.
   * @this {Blockly.Block}
   
  updateFields: function() {
    Blockly.Arduino.Boards.refreshBlockFieldDropdown(
        this, 'BUILT_IN_LED', 'builtinLed');
  },*/
  /** @return {!string} The type of input value for the block, an integer. */
  getBlockType: function() {
    return Blockly.Names.NameType.BOOLEAN;
  },
};

blocks['io_analogwrite'] = {
  /**
   * Block for creating a 'set pin' to an analogue value.
   * @this {Blockly.Block}
   */
  init: function() {
    this.setHelpUrl('http://arduino.cc/en/Reference/AnalogWrite');
    this.appendValueInput('NUM')
        .appendField(Blockly.Msg.ARD_ANALOGWRITE)
        .appendField(new Blockly.FieldDropdown(
            Blockly.Hardware.availablePWMPorts), 'PIN')
        .appendField(Blockly.Msg.ARD_WRITE_TO)
        .setCheck([Blockly.Names.NameType.INTEGER, Blockly.Names.NameType.BYTE]);
    this.setInputsInline(false);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setStyle('io_blocks');
    this.setTooltip(Blockly.Msg.ARD_ANALOGWRITE_TIP);
  },
  /**
   * Updates the content of the the pin related fields.
   * @this {Blockly.Block}
   
  updateFields: function() {
    Blockly.Arduino.Boards.refreshBlockFieldDropdown(this, 'PIN', 'pwmPins');
  },*/
  /** @return {!string} The type of input value for the block, an integer. */
  getBlockType: function() {
    return [Blockly.Names.NameType.INTEGER, Blockly.Names.NameType.BYTE];
  },
};

blocks['io_analogread'] = {
  /**
   * Block for reading an analogue input.
   * @this {Blockly.Block}
   */
  init: function() {
    this.setHelpUrl('http://arduino.cc/en/Reference/AnalogRead');
    this.appendDummyInput()
        .appendField(Blockly.Msg.ARD_ANALOGREAD)
        .appendField(new Blockly.FieldDropdown(
            Blockly.Hardware.availableAnalogPorts), 'PIN');
    this.setOutput(true, [Blockly.Names.NameType.INTEGER, Blockly.Names.NameType.BYTE]);
    this.setStyle('io_blocks');
    this.setTooltip(Blockly.Msg.ARD_ANALOGREAD_TIP);
  },
  /** @return {!string} The type of return value for the block, an integer. */
  getBlockType: function() {
    return [Blockly.Names.NameType.INTEGER, Blockly.Names.NameType.BYTE];
  },
  /**
   * Updates the content of the the pin related fields.
   * @this {Blockly.Block}
   
  updateFields: function() {
    Blockly.Arduino.Boards.refreshBlockFieldDropdown(this, 'PIN', 'analogPins');
  }*/
};

blocks['io_highlow'] = {
  /**
   * Block for creating a pin state.
   * @this {Blockly.Block}
   */
  init: function() {
    this.setHelpUrl('http://arduino.cc/en/Reference/Constants');
    this.appendDummyInput()
        .appendField(
            new Blockly.FieldDropdown([[Blockly.Msg.ARD_HIGH, 'HIGH'], [Blockly.Msg.ARD_LOW, 'LOW']]),
           'STATE');
    this.setOutput(true, Blockly.Names.NameType.BOOLEAN);
    this.setStyle('io_blocks');
    this.setTooltip(Blockly.Msg.ARD_HIGHLOW_TIP);
  },
  /** @return {!string} The type of return value for the block, an integer. */
  getBlockType: function() {
    return Blockly.Names.NameType.BOOLEAN;
  }
};
blocks['io_pulsein'] = {
  /**
   * Block for measuring the duration of a pulse in an input pin.
   * @this {Blockly.Block}
   */
  init: function() {
    this.jsonInit({
      "type": "math_foo",
      "message0": Blockly.Msg.ARD_PULSE_READ,
      "args0": [{
          "type": "input_value",
          "name": "PULSETYPE",
          "check": Blockly.Names.NameType.BOOLEAN
        }, {
          "type": "field_dropdown",
          "name": "PULSEPIN",
          "options": Blockly.Hardware.availableDigitalPorts
        }
      ],
      "output": [Blockly.Names.NameType.INTEGER, Blockly.Names.NameType.BYTE],
      "inputsInline": true,
      "style": 'io_blocks',
      "tooltip": Blockly.Msg.ARD_PULSE_TIP,
      "helpUrl": 'https://www.arduino.cc/en/Reference/PulseIn'
    });
  },
  /** @return {!string} The type of input value for the block, an integer. */
  getBlockType: function() {
    return [Blockly.Names.NameType.INTEGER, Blockly.Names.NameType.BOOLEAN];
  }
};

blocks['io_pulsetimeout'] = {
  /**
   * Block for measuring (with a time-out) the duration of a pulse in an input
   * pin.
   * @this {Blockly.Block}
   */
  init: function () {
    this.jsonInit({
      "type": "math_foo",
      "message0": Blockly.Msg.ARD_PULSE_READ_TIMEOUT,
      "args0": [{
          "type": "input_value",
          "name": "PULSETYPE",
          "check": Blockly.Names.NameType.BOOLEAN
        }, {
          "type": "field_dropdown",
          "name": "PULSEPIN",
          "options": Blockly.Hardware.availableDigitalPorts
        }, {
          "type": "input_value",
          "name": "TIMEOUT",
          "check": [Blockly.Names.NameType.INTEGER, Blockly.Names.NameType.BYTE]
        }
      ],
      "output": [Blockly.Names.NameType.INTEGER, Blockly.Names.NameType.BYTE],
      "inputsInline": true,
      "style": "io_blocks",
      "tooltip": Blockly.Msg.ARD_PULSETIMEOUT_TIP,
      "helpUrl": 'https://www.arduino.cc/en/Reference/PulseIn'
    });
  },
  /** @return {!string} The type of input value for the block, an integer. */
  getBlockType: function() {
    return [Blockly.Names.NameType.INTEGER, Blockly.Names.NameType.BYTE];
  }
};

defineBlocks(blocks);
