/**
 * @license
 * Copyright 2012 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

import * as goog from '../closure/goog/goog.js';
goog.declareModuleId('Blockly.libraryBlocks.gen_io');

import * as Extensions from '../core/extensions.js';
import type {FieldDropdown} from '../core/field_dropdown.js';
import * as xmlUtils from '../core/utils/xml.js';
import type {Block} from '../core/block.js';
import { Names } from '../core/names.js';
import {
  createBlockDefinitionsFromJsonArray,
  defineBlocks,
} from '../core/common.js';
import '../core/field_dropdown.js';
import '../core/field_label.js';
import '../core/field_number.js';
import '../core/field_variable.js';

/**
 * A dictionary of the block definitions provided by this module.
 */
export const blocks = createBlockDefinitionsFromJsonArray([
  // Block for numeric value.
  {
    'type': 'g_io_digitalread',
    'message0': '%{BKY_GEN_IO_DIGITALREAD}',
    'args0': [
      {
        'type': 'field_label_serializable',
        'name': 'ModuleName',
        'value': 'Module1',
      },
    ],
    'output': ['boolean'],
    'helpUrl': '',
    'style': 'io_blocks',
    'tooltip': '%{BKY_GEN_IO_DIGITALREAD_TOOLTIP}',
  },
  {
    'type': 'g_io_digitalwrite',
    'message0': '%{BKY_GEN_IO_DIGITALWRITE}',
    'args0': [
      {
        'type': 'field_label_serializable',
        'name': 'ModuleName',
        'value': 'Module1',
      },
      {
        'type': 'input_value',
        'name': 'STATE',
        'check': 'boolean',
      },
    ],
    'previousStatement': null,
    'nextStatement': null,
    'style': 'io_blocks',
    'tooltip': '%{BKY_GEN_IO_DIGITALWRITE_TOOLTIP}',
  },
  {
    'type': 'g_io_analogread',
    'message0': '%{BKY_GEN_IO_ANALOGREAD}',
    'args0': [
      {
        'type': 'field_label_serializable',
        'name': 'ModuleName',
        'value': 'Module1',
      },
    ],
    'output': ['boolean'],
    'helpUrl': '',
    'style': 'io_blocks',
    'tooltip': '%{BKY_GEN_IO_ANALOGREAD_TOOLTIP}',
  },
  {
    'type': 'g_io_analogwrite',
    'message0': '%{BKY_GEN_IO_ANALOGWRITE}',
    'args0': [
      {
        'type': 'field_label_serializable',
        'name': 'ModuleName',
        'value': 'Module1',
      },
      {
        'type': 'input_value',
        'name': 'STATE',
        'check': 'boolean',
      },
    ],
    'previousStatement': null,
    'nextStatement': null,
    'style': 'io_blocks',
    'tooltip': '%{BKY_GEN_IO_ANALOGWRITE_TOOLTIP}',
  },
  {
    'type': 'g_io_pulsein',
    'message0': '%{BKY_GEN_PULSE_READ}',
    'args0': [
      {
        'type': 'input_value',
        'name': 'PULSETYPE',
        'check': Names.NameType.BOOLEAN,
      },
      {
        'type': 'field_label_serializable',
        'name': 'ModuleName',
        'value': 'Module1',
      },
    ],
    'output': [Names.NameType.INTEGER, Names.NameType.BYTE],
    'inputsInline': true,
    'helpUrl': '',
    'style': 'io_blocks',
    'tooltip': '%{BKY_GEN_PULSE_READ_TOOLTIP}',
  },
  {
    'type': 'g_io_pulsetimeout',
    'message0': '%{BKY_GEN_PULSE_READ_TIMEOUT}',
    'args0': [
      {
        'type': 'input_value',
        'name': 'PULSETYPE',
        'check': Names.NameType.BOOLEAN,
      },
      {
        'type': 'field_label_serializable',
        'name': 'ModuleName',
        'value': 'Module1',
      },
      {
        'type': 'input_value',
        'name': 'TIMEOUT',
        'check': [Names.NameType.INTEGER, Names.NameType.BYTE]
      },
    ],
    'output': [Names.NameType.INTEGER, Names.NameType.BYTE],
    'inputsInline': true,
    'helpUrl': '',
    'style': 'io_blocks',
    'tooltip': '%{BKY_GEN_PULSETIMEOUT_TOOLTIP}',
  },
  
]);

// Register provided blocks.
defineBlocks(blocks);
