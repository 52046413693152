'use strict';

goog.module('Blockly.libraryBlocks.modules');

/* eslint-disable-next-line no-unused-vars */
const AbstractEvent = goog.requireType('Blockly.Events.Abstract');
const Events = goog.require('Blockly.Events');
const Extensions = goog.require('Blockly.Extensions');
const xmlUtils = goog.require('Blockly.utils.xml');
/* eslint-disable-next-line no-unused-vars */
const {Block} = goog.requireType('Blockly.Block');
// const {BlockDefinition} = goog.requireType('Blockly.blocks');
// TODO (6248): Properly import the BlockDefinition type.
/* eslint-disable-next-line no-unused-vars */
const BlockDefinition = Object;
const {Msg} = goog.require('Blockly.Msg');
/* eslint-disable-next-line no-unused-vars */
const {RenderedConnection} = goog.requireType('Blockly.RenderedConnection');
/* eslint-disable-next-line no-unused-vars */
const {Workspace} = goog.requireType('Blockly.Workspace');
const {createBlockDefinitionsFromJsonArray, defineBlocks} = goog.require('Blockly.common');
/** @suppress {extraRequire} */
goog.require('Blockly.Mutator');
/** @suppress {extraRequire} */
goog.require('Blockly.FieldDropdown');
/** @suppress {extraRequire} */
goog.require('Blockly.FieldLabel');

const blocks = {};
exports.blocks = blocks;

/**************************************************
 * BLOCKS for DHT22 Module
 **************************************************/

blocks['dht22-get-temperature']={
  init:function(){
    this.appendDummyInput()
        .appendField(new Blockly.FieldLabelSerializable("Sensor1"),"ModuleName")
        .appendField("DHT22 : Temperature");
    this.setOutput(true, 'float');
    this.setStyle('module_dynamic_blocks');
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

blocks['dht22-get-humidity']={
  init:function(){
    this.appendDummyInput()
        .appendField(new Blockly.FieldLabelSerializable("Sensor1"),"ModuleName")
        .appendField("DHT22 : Humidity");
    this.setOutput(true, 'float');
    this.setStyle('module_dynamic_blocks');
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

/**************************************************
 * BLOCKS for Soil-Moisture Module
 **************************************************/
blocks['soil-moisture']={
  init:function(){
    this.appendDummyInput()
        .appendField("Soil-Moisture Module")
        .appendField(new Blockly.FieldLabelSerializable("name"), "ModuleName")
        .appendField("Get Moisture");
    this.setOutput(true, "int");
    this.setStyle('module_dynamic_blocks');
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

/**************************************************
 * BLOCKS for LCD-I2C Module
 **************************************************/
blocks['lcd-display-on-line']={
  init:function(){
    this.appendValueInput("Message")
        .setCheck("charArray")
        .appendField("(LCD:")
        .appendField(new Blockly.FieldLabelSerializable("Name)"), "ModuleName")
        .appendField("Show message on line")
        .appendField(new Blockly.FieldDropdown(
          [["Line1", "0"],["Line2", "1"],["Line3", "2"],["Line4", "3"]]
        ), "LineNo");
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setStyle('module_dynamic_blocks');
    this.setTooltip("");
    this.setHelpUrl("");
  }
}



defineBlocks(blocks);